import React from 'react';
import '../styles/app.scss';
import Card from './Card';

const cardList = [
  {
    album: 'nature',
    id: 4877,
    title: 'nemo possimus ut quam',
    likes: 53,
    views: 122,
    tags: ['dark', 'orange', 'deep'],
    image:
      'https://skitterphoto.com/photos/skitterphoto-9530-thumbnail.jpg',
  },
  {
    album: 'nature',
    id: 4878,
    title: 'aut rerum nobis veniam veritatis',
    likes: 4,
    views: 32,
    tags: ['sky', 'buildings', 'window'],
    image:
      'https://skitterphoto.com/photos/skitterphoto-9668-thumbnail.jpg',
  },
  {
    album: 'buildings',
    id: 4879,
    title: 'vel alias non',
    likes: 77,
    views: 234,
    tags: ['city', 'road'],
    image:
      'https://skitterphoto.com/photos/skitterphoto-9528-thumbnail.jpg',
  },
  {
    album: 'buildings',
    id: 4880,
    title: 'velit architecto ut omnis rem nisi ab debitis',
    likes: 1,
    views: 3,
    tags: ['lake', 'water', 'skysreaper'],
    image:
      'https://skitterphoto.com/photos/skitterphoto-3791-thumbnail.jpg',
  },
  {
    album: 'nature',
    id: 4881,
    title: 'rem molestias vero aliquam',
    likes: 465,
    views: 33434,
    tags: ['garden', 'green', 'river'],
    image:
      'https://skitterphoto.com/photos/skitterphoto-9964-thumbnail.jpg',
  },
  {
    album: 'nature',
    id: 4882,
    title: 'culpa odio esse rerum omnis laboriosam',
    likes: 202,
    views: 561,
    tags: ['river', 'city', 'water'],
    image:
      'https://skitterphoto.com/photos/skitterphoto-10003-thumbnail.jpg',
  },
  {
    album: 'nature',
    id: 4883,
    title: 'natus nisi omnis corporis facere molestiae',
    likes: 76,
    views: 499,
    tags: ['green', 'grass', 'cloud'],
    image:
      'https://skitterphoto.com/photos/skitterphoto-10208-thumbnail.jpg',
  },
  {
    album: 'nature',
    id: 4884,
    title: 'accusamus ea aliquid et amet sequi nemo',
    likes: 578,
    views: 2434,
    tags: ['night', 'dark', 'city'],
    image:
      'https://skitterphoto.com/photos/skitterphoto-9286-thumbnail.jpg',
  },
];

export default class App extends React.Component {
  constructor(props) {
    super(props);

    this.forClass = '';
  }

  render() {
    return (
      <div className="page">
        <div className="search-wrapper">
          <h1 className="title">Google Powered</h1>

          <p className="sub-title">Site Search</p>

          <form action="" className="form">
            <span className="search-icon">⌕</span>
            <input
              type="search"
              placeholder="picture"
              className="search-input"
            />

            <button type="button" className="button search-button">
              Search
            </button>
          </form>
        </div>

        <div className="card-list">
          {cardList.map((card) => (
            <Card card={card} key={card.id} />
          ))}
        </div>
      </div>
    );
  }
}
