import React from 'react';

export default class Card extends React.Component {
  constructor(props) {
    super(props);

    this.forClass = '';
  }

  render() {
    const { card } = this.props;

    return (
      <div className="card" key={card.id}>
        <div className="card__image-wrapper">
          <img
            src={card.image}
            alt={card.album}
            className="card__image"
          />
        </div>

        <div className="card__info-wrapper">
          <h3 className="card__title">{card.title}</h3>

          <div className="card__info">
            <span>&#9829;&nbsp;{card.likes}</span>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <span>
              <span className="card__icon card__icon_type_views">
                &#128065;
              </span>
              &nbsp;
              {card.views}
            </span>
          </div>

          <div className="card__tag-list">
            {card.tags.map((tag) => (
              <div className="card__tag">{tag}</div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
